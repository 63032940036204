section{
   @include section-base(50px 0, 300px);
}
.banner{
	padding: 0;
	background: #fff;
	padding-bottom: 30px;
	position: relative;
	max-height: 700px;

	@media (max-width: 990px){
		background: $bg-gradient;
	}
	.container{
		padding-bottom: 45%;
		@media (max-width: 990px){
			padding-bottom: 500px;
		}
	}

	.content-middle{
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		width: 55%;
		min-height: 300px;
		transform: translateY(-50%);

		@media (max-width: 1100px){
			top: 70%;
			
		}

		@media (max-width: 990px){
			text-align: center;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			width: 100%;
			top: 55%;
		}

		h1{
			font-weight: bold;
			font-size: 40px;
			margin-bottom: 30px;
			@media (max-width: 990px){
				color: #fff;
			}
		}

		p{
			margin-bottom: 40px;
			@media (max-width: 990px){
				color: #fff;
			}
		}

		a{
			@media (max-width: 990px){
				margin: 0 auto;
			}
		}
	}

	.bg-banner{
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		@media (max-width: 990px){
			display: none;
		}
	}
}
.content-servicios{
	position: relative;
	background: #fbfdf9;
	&:before{
		content: "";
		width: 100%;
		height: 100%;
		background: #f7f7fa;
		display: block;
		position: absolute;
		z-index: 1;
		top: 0;
		-webkit-clip-path: polygon(0 0, 100% 0, 0 13%);
		clip-path: polygon(0 0, 100% 0, 0 13%);
	}
	.container{
		z-index: 2;
	}
	.item_servicios{
		display: block;
		overflow: hidden;
		margin-bottom: 100px;
		margin-top: 100px;
		position: relative;
		

		@media (max-width: 990px){
			
			margin-bottom: 20px;
			margin-top: 50px;
		}


		.box-servicio{
			display: block;
			float: left;
			overflow: hidden;
			width: 49%;
			margin-right: 2%;
			position: relative;

			@media (max-width: 990px){
				width: 100%;
				height: auto;
				margin-top: 30px;
			}
			
			&:nth-child(2n){
				margin-right: 0;
			}

			h3{
				font-size: 30px;
				margin-bottom: 30px;
				font-weight: bold;
			}

			p{
				font-size: 15px;
			}

			a{
				margin-top: 30px; 
			}

			img{
				display: block;
				width: 100%;
				height: auto;
			}

			.box-middle{
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				transform: translateX(-50%) translateY(-50%);

				@media (max-width: 990px){
					position: relative;
					top: 0;
					left: 0;
					transform: translateX(0) translateY(0);
				}
			}
		}
		.padding-30{
			padding-bottom: 30%;
			@media (max-width: 990px){
				padding: 0;
			}
		}
		.active-mobile{
			display: none;
		
			@media (max-width: 990px){
				display: block;
			}
		}
		
		.disable-mobile{
			display: block;
		
			@media (max-width: 990px){
				display: none;
			}
		}
	}
}


.content-clientes{
	min-height: 0;
	padding: 70px 0;
}

.content-contacto{
	position: relative;
	color: #fff;
	padding-top: 50px;
	
	

	&:before{
		content: "";
		background: $bg-gradient;
		width: 100%;
		height: 50%;
		top: 0;
		position: absolute;
		z-index: 0;
	}

	&:after{
		content: "";
		background: url('../img/triangulos.svg');
		background-size: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 0;
		height: 50%;
		opacity: .5;

		@media (max-width: 680px){
			width: 200%;
		}
	}
	.container{
		z-index: 2;
	}

	h3{
		font-size: 30px;
		margin-bottom: 30px;
		font-weight: bold;
		color: #fff;
		text-align: center;
	}

	p{
		color: #fff;
		text-align: center;
	}

	a{
		margin: 40px auto;
	}

	.item_contacto{
		position: relative;
		display: block;
		width: 100%;
		margin: 50px auto;
		height: auto;
		background:#fff;
		border-radius: 30px;
		overflow: hidden;

		.box-date, .box-maps{
			display: block;
			float: left;
			width: 50%;
			position: relative;
			color: $second-color;

			h3{
				color: $second-color;
				text-align: left;
				margin-top: 10%;
			}

			@media (max-width: 990px){
				width: 100%;
			}
		}

		.list-date{
			display: block;
			overflow: hidden;
			li{
				display: block;
				height: auto;
				line-height: normal;
				margin-bottom: 10px;
				font-size: 15px;

				i{
					color: $primary-color - 30;
					font-size: 30px;
					width: 40px;
					height: 40px;
				}
			}
		}

		.acrtion_llega{
			display: block;
			width: 90%;
			max-width: 400px;
			background: $primary-color;
			color: $third-color;
			text-align: center;
			height: 50px;
			line-height: 50px;
			text-transform: uppercase;
			margin: 50px 0 0 0;
		
			&:hover{
				background: $primary-color - 20;
			}
		}
		.box-date{
			padding: 30px;
		}

		.box-maps{
			height: 600px;
		}
	}
}



.inner-banner{
	position: relative;
	color: #fff;
	padding-top: 50px;
	height: 300px;

	
	

	&:before{
		content: "";
		background: $bg-gradient;
		width: 100%;
		height: 100%;
		top: 0;
		position: absolute;
		z-index: 0;
	}

	&:after{
		content: "";
		background: url('../img/triangulos.svg');
		background-size: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 0;
		height: 100%;
		opacity: .5;

		@media (max-width: 680px){
			width: 200%;
		}
	}

	.content-middle{
		position: absolute;
		top: 60%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		width: 90%;
		max-width: 1280px;

		h1{
			color: #fff;
			text-align: center;
			text-transform: uppercase;
			font-size: 35px;
			line-height: normal;

			span, strong{
				text-transform: initial;
				font-size: 18px;
				display: block;
			}
		}
	}
}