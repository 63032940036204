@mixin section-base($padding, $min-height){
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: $padding;
    min-height: $min-height;
    display: block;
    position: relative;
}


@mixin item{
    display: block;
    height: auto;
    overflow: hidden;
    width: 100%;
}

@mixin boton{
    display: block;
    border: 0;
    width: 100%;
    color: #fff;
    background: $primary-color;
    height: 40px;
    text-align: center;
    box-sizing: border-box;
    padding: 0;
    outline: 0;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 40px;
    font-size: 11px;
}