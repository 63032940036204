/*
 * 	Owl Carousel - Lazy Load Plugin
 */

.owl-carousel, .owl-carousel-2 {
	.owl-item {
		.owl-lazy {
				opacity: 0;
				transition: opacity 400ms ease;
		}

		img.owl-lazy {
			transform-style: preserve-3d;
		}
	}
}
