input, textarea{
	display: block;
	width: 100%;
	height: 30px;
	box-sizing: border-box;
	outline: none;
	border: 1px solid #e5e5e5;
	padding-left: 10px;
}

textarea{
	height: 100px;
	resize: none;
	padding-top: 10px;
}


label{
	font-size: 12px;
	margin-bottom: 5px;
	display: block;
}


.input-form{
	display: block;
	height: auto;
	overflow: hidden;
	margin-bottom: 10px;
}


.enviar{
	display: block;
	height: 40px;
	margin-top: 30px;
	background: $primary-color;
	border: 0;
	width: 100%;
	color: #fff;
	max-width: 200px;
	text-transform: uppercase;
	cursor: pointer;
	transition: all .4s;
	-webkit-transition: all .4s;
	-moz-transition: all .4s;
	-ms-transition: all .4s;
	-o-transition: all .4s;

		&:hover{
			background: $primary-color - 20;
		}
}


