
* {
   box-sizing: border-box;
}

html {
   height: 100%;
   width: 100%;
}

body {
   font-family: 'Montserrat', sans-serif;
   color: #000;
   width: 100%;
   background: #f7f7fa;
   font-size: 15px;
}

a{
   text-decoration: none;
}



.container{
    display: block;
    overflow: hidden;
    height: auto;
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
}



h2, h3, h4{
    color: $second-color;
  
}

h2{
    text-align: center;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 30px;
}

p{
    color: $second-color;
    line-height: normal;
}


.ver_mas, .ver_mas_bank{
    display: block;
    width: 90%;
    max-width: 400px;
    background: $primary-color;
    color: $third-color;
    text-align: center;
    height: 50px;
    line-height: 50px;
    text-transform: uppercase;

    &:hover{
        background: $primary-color - 20;
    }
}

.ver_mas_bank{
    background: #fff;
    color: $primary-color;

    &:hover{
        background: #fff - 10; 
    }
}


main{
    min-height: 300px;
}




.form-post{
    display: block;
    overflow: hidden;
    height: auto;
    width: 100%;

    form{
        max-width: 800px;
        width: 90%;
        margin: 50px auto;
        display: block;
        overflow: hidden;

        .form-group{
            display: block;
            height: auto;
            width: 100%;
            margin-bottom: 20px;
            select{
                width: 100%;
                height: 40px;
                border: 1px solid #e5e5e5;
                background: #fff;
            }

            label{
                margin-bottom: 7px;
            }

            input{
                height: 40px;
            }
            input, textarea, select{
                opacity: .5;
                outline: none;
            }

            button{
                display: block;
                color: #fff;
                background: $primary-color;
                height: 40px;
                width: 90%;
                max-width: 250px;
                font-size: 15px;
                border: 0;
                text-transform: uppercase;
                font-family: 'Montserrat', sans-serif;

                &:hover{
                    background: $primary-color - 30;
                }
            }
        }
    }
}



.content-contacto{
    display: block;
      
      
   .contacto-info{
      display: block;
      background: red;
      margin-top: 30px;
      margin-bottom: 20px;
      
      li{
         float: left;
         display: block;
         width: 32%;
         margin-right: 2%;
         margin-bottom: 2%;
         overflow: hidden;
         text-align: center;
         
         @media (max-width: 480px){
            width: 100%;
            margin-bottom: 8%;
         }
         
         &:nth-child(3n){
            margin-right: 0;
         }
         
         i{
            margin-bottom: 10px;
            font-size: 30px;
         }
      }
   }

   
    .form-group{
        display: block;
        height: auto;
        width: 100%;
        margin-bottom: 20px;
        select{
            width: 100%;
            height: 40px;
            border: 1px solid #e5e5e5;
            background: #fff;
        }

        label{
            margin-bottom: 7px;
        }

        input{
            height: 40px;
        }
        input, textarea, select{
            opacity: .5;
            outline: none;
        }

        button{
            display: block;
            color: #fff;
            background: $primary-color;
            height: 40px;
            width: 90%;
            max-width: 250px;
            font-size: 15px;
            border: 0;
            text-transform: uppercase;
            font-family: 'Montserrat', sans-serif;

            &:hover{
                background: $primary-color - 30;
            }
        }
    }
}



.content-portafolio{
    overflow: hidden;
    .item_portafolio{
        display: block;
        overflow: hidden;
        width: 100%;
        height: auto;

        .box-title{
            color: #fff;

            h2{
                background: $primary-color;
                padding: 10px 0 10px 10px; 
                display: block;
                color: #fff;
                text-transform: uppercase;
                font-weight: 400;

                i{
                    margin-right: 10px;
                }
            }

        }

        .gallery{
            display: block;
            overflow: hidden;
            width: 100%;
            height: auto;
            margin: 50px auto;

            .box-img-gallery{
                display: block;
                position: relative;
                padding-bottom: 66.7%;
                overflow: hidden;

                img{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    transform: translateX(-50%) translateY(-50%);
                    transition: all .4s;

                    &:hover{
                        width: 150%;

                    }
                }
            }
        }
    }
}