.content-somos{
    overflow: hidden;
    width: 100%;
    height: auto;

    h2{
        font-size: 20px;
    }

    p{
        text-align: justify;
    }

    .content-galeria_somos{
        margin-top: 30px;
        display: block;
        overflow: hidden;

        .item{
            display: block;
            float: left;
            width: 32%;
            height: auto;
            margin-right: 1%;
            margin-bottom: 1%;
            overflow: hidden;
            position: relative;
            &:nth-child(3n){
                margin-right: 0; 
            }

            @media (max-width: 990px){
                width: 49%;
                &:nth-child(2n){
                    margin-right: 0; 
                }
                &:nth-child(3n){
                    margin-right: 1%; 
                }
            }
            @media (max-width: 480px){
                width: 100%;
            }
            img{
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }
}


.content-valor{
    display: block;
    background: #f9f9f9;
    .box-text, .box-img{
        display: block;
        float: left;
        width: 46%;
        margin-right: 8%;
        
        @media (max-width: 990px){
            width: 100%;
            margin: 0;
        }

        h3{
            margin-top: 10%;
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 20px;
        }
        p{
            margin-bottom: 10%;
            text-align: justify;
            font-size: 14px;
            line-height: normal;
        }

        img{
            display: block;
            width: 100%;
        }
        &:nth-child(2n){
            margin-right: 0;
        }
    }
}

.content-areas{
    min-height: 0;
    background: #f5f5f5;

    .item_areas{
        display: block;
        float: left;
        width: 32%;
        margin-right: 2%;
        margin-bottom: 2%;
        overflow: hidden;
        text-align: center;

        &:nth-child(2n){
            margin-right: 0;
        }

        i{
            margin: 0 auto;
            color: $primary-color;
            font-size: 70px;
            margin-bottom: 2%;
        }
        img{
            display: block;
            max-width: 70px;
            margin: 0 auto;
            margin-bottom: 2%;
        }
        span{
            display: block;
        }
    }
}