.top-header{
    background: #fff;
    display: block;
    overflow: hidden;
    width: 100%;
    color: $primary-color;
    height: 30px;
    
        p{
            font-weight: 300;
            font-size: 12px;
            float: left;
            margin-right: 2%;
            line-height: 30px;
            @media (max-width: 990px){
                color: #fff;
             }
        }

        @media (max-width: 990px){
            background: $primary-color;
         }
}




header{
    display: block;
    overflow: hidden;
    width: 100%;
    z-index: 6;
    background: transparent;
    position: absolute;
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;

    @media (max-width: 990px){
         display: none;
      }
    
        nav{
            display: inline-block;
            vertical-align: middle;
            text-align: left;
            font-size: 14px;
            float: right;
            
            ul.nav-desktop{
                list-style: none;
                margin: 0;
                padding: 0;
                
                li{
                    float: left;
                    position: relative;
                    
                    a{
                        display: block;
                        padding: 0 20px;
                        line-height: 60px;
                        font-family: Source Sans Pro, sans-serif;
                        color: #fff;
                        font-weight: 700;
                        text-transform: uppercase;
                        font-size: 13px;
                        -webkit-transition: all .2s ease;
                        -moz-transition: all .2s ease;
                        -ms-transition: all .2s ease;
                        -o-transition: all .2s ease;
                        
                            &:hover{
                            color: #fff;
                        }


                    }
                    &:hover{
                        background: $primary-color;
                    }
                }

            .current_page_item, .current-menu-item{
                background: $primary-color;
                color: #fff;
            }
        }
    }

    .logo{
        display: inline-block;
        margin-top: 7px;
        img{
            display: block;
        }
    }
}



.header-mobile{
    display: none;
    width: 100%;
    z-index: 10;
    height: 60px;
    background: #fff;
    position: absolute;
    top: 30px;
    border-bottom: 1px solid #fff - 40;
    
    @media (max-width: 990px){
         display: block;
      }

      .logo{
            display: inline-block;
            margin-top: 13px;
                img{
                    display: block;
                }
        }

        ul.nav-mobile{
            display: none;
            height: auto;
            left: 0;
            list-style: outside none none;
            position: absolute;
            text-align: center;
            top: 60px;
            width: 100%;

            li{
                background: #fff;
                border-bottom: 1px solid #fff - 10;
                height: 50px;
                line-height: 50px;
                transition: all .4s;
                -moz-transition: all .4s;
                -webkit-transition: all .4s;

                a{
                    color: #333;
                    display: block;
                    height: 100%;
                    text-transform: uppercase;
                    width: 100%;
                    font-size: 12px;

                    &:hover{
                        color: #fff;
                    }
                }
            }
            &:hover{
                background-color: $primary-color;
            }
            
        }

        .current_page_item, .current-menu-item{
            background: $primary-color;
            color: #fff;
        }

    .down-menu {
        cursor: pointer;
        display: block;
        font-size: 25px;
        font-weight: 100;
        height: 60px;
        line-height: 55px;
        position: absolute;
        right: 0;
        text-align: center;
        text-transform: uppercase;
        width: 60px;
        color: #333;
        z-index: 99;
    }    
}



.header-fixed{
  position: fixed;
  top: 0;
  transition: all .5s;
  -moz-transition: all .5s;
  -webkit-transition: all .5s;
  background: #fff;
  border-bottom: 1px solid #ddd;


  nav{
    ul.nav-desktop{
        li{
            a{
                color: #333;
            }
        }

        .current_page_item, .current-menu-item{
            background: $primary-color;
            color: #fff;

            a{
                color: #fff;
            }
        }
        
    }
  }
}












