.content-inner-servicios{
    display: block;

    .bg-letter, .bg-table{
        width: 100%;
        overflow: hidden;
        height: auto;
        min-height: 200px;
    }

    .bg-table{
        background: #f9f9f9;
        padding: 30px 0;
        position: relative;
      
        .item_table{
            width: 100%;
            position: relative;
            height: 1%;
            overflow: hidden;

   
            .strong-table{
                font-weight: 700;
                color: #666;
            }

            .box-title, .box-table{
                display: block;
                float: left;
            }
  
            .box-title{
                background: $bg-gradient;
                width: 10%;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;

                @media(max-width: 768px){
                    width: 100%;
                    position: relative;
                    padding: 20px 0;
                    background: $primary-color;
                }

                h1{
                    color:#fff;
                    transform: rotate(-90deg);
                    text-transform: uppercase;
                    position: relative;
                    top: 50%;

                    @media(max-width: 768px){
                        transform: rotate(0deg);
                        text-align: center;
                    }
                }
            }

            .box-table{
                width: 90%;
                float: right;

                @media(max-width: 768px){
                    width: 100%;
                }
            }
        }
      
    }

    .item_productos{
        display: block;
        overflow: hidden;
        position: relative;

        h2{
            font-size: 30px;
        }
        img, p{
            margin-bottom: 20px;

            strong, span{
                color: $primary-color;
                font-weight: 700;
            }
        }

        p{
            text-align: center;
        }
    }

    .item_servicios{
        display: block;
        overflow: hidden;
        margin-bottom: 100px;
        margin-top: 100px;
        position: relative;
        
    
        @media (max-width: 990px){
            
            margin-bottom: 20px;
            margin-top: 50px;
        }
    
    
        .box-servicio{
            display: block;
            float: left;
            overflow: hidden;
            width: 49%;
            margin-right: 2%;
            position: relative;
    
            @media (max-width: 990px){
                width: 100%;
                height: auto;
                margin-top: 30px;
            }
            
            &:nth-child(2n){
                margin-right: 0;
            }
    
            h3{
                font-size: 30px;
                margin-bottom: 30px;
                font-weight: bold;
            }
    
            p{
                font-size: 15px;
            }
    
            a{
                margin-top: 30px; 
            }
    
            img{
                display: block;
                width: 100%;
                height: auto;
            }
    
            .box-middle{
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                transform: translateX(-50%) translateY(-50%);
    
                @media (max-width: 990px){
                    position: relative;
                    top: 0;
                    left: 0;
                    transform: translateX(0) translateY(0);
                }
            }
        }
        .padding-30{
            padding-bottom: 30%;
            @media (max-width: 990px){
                padding: 0;
            }
        }
        .active-mobile{
            display: none;
        
            @media (max-width: 990px){
                display: block;
            }
        }
        
        .disable-mobile{
            display: block;
        
            @media (max-width: 990px){
                display: none;
            }
        }
    }
}
