footer{
    display: block;
    height: auto;
    background: #f7f7fa;
    color: $second-color;
    padding: 40px 0;
    line-height: normal;
   
    .container{
        border-top: 1px solid #ddd;
    }

    .item_footer{
        display: block;
        overflow: hidden;
        margin: 40px 0;

        .logo{
            display: block;
            margin: 0 auto;
            max-width: 300px;;
        }

        p{
            margin-top: 30px;
            text-align: center;
        }


        .content-menu-footer{
            display: block;
            margin: 40px auto;
            .item_menu{
                display: block;
                float: left;
                overflow: hidden;
                width: 32%;
                margin-right: 2%;
                box-sizing: border-box;
                padding: 20px;

                @media (max-width: 728px){
                    width: 100%;
                }
    
                &:nth-child(3n){
                    margin-right: 0;
                }

                h3{
                    text-transform: uppercase;
                    font-weight: bold;
                    margin-bottom: 20px;
                }

                ul{
                    overflow: hidden;
                    li{
                        display: block;
                        width: 100%;
                        margin-bottom: 7px;

                        a{
                            color: $second-color;
                            font-size: 15px;
                            width: 100%;
                            display: block;

                            &:hover{
                                color: $primary-color;
                            }

                            i{
                                color: $primary-color;
                                font-size: 7px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
       
    }

}

.copy{
    background: #f3f3f3;
    line-height: 50px;
    text-align: left;
    font-size: 15px; 
}